jQuery(document).ready(function($){
	
	/* Search Menu */
	
	$(".search-icon").click(function() {
		$(".navsearchform").animate({width: 'toggle'});
	});

	$(document).keyup(function(e) {
		// Ref https://stackoverflow.com/questions/3369593/how-to-detect-escape-key-press-with-pure-js-or-jquery
		// Close search if esc key pressed
		if (e.key == "Escape") {
		  $(".navsearchform").hide();
		}
	});

});

jQuery(document).ready(function($){
	        // Add minus icon for collapse element which is open by default
        $(".collapse.show").each(function(){
        	$(this).prev(".card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
        });
        
        // Toggle plus minus icon on show hide of collapse element
        $(".collapse").on('show.bs.collapse', function(){
        	$(this).prev(".card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
        }).on('hide.bs.collapse', function(){
        	$(this).prev(".card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
        });

        //S&F
        $(window).bind('load', function() {
            $('body .select2-search.select2-search--inline')
                .append('<i class="fa fa-caret-down"></i>');
        });

        $('.sf-input-select').each(function() {
            var title = $(this).attr('data-placeholder');
            // console.log(title);
            $(this).after('<span class="select_placeholder">'+title+'</span>');
        });

        $('.select_placeholder').click(function() {
            //console.log('test');
            $(this).prev().find($('.select2-selection__rendered')).trigger('click');
        });
});
